
import Vue from "vue"

const schemasPneu = require("../assets/shemasPneu.json")

export default Vue.extend({
  name: "ShemasPneu",
  data: () => ({
    schemasPneu: JSON.parse(JSON.stringify(schemasPneu)),
    maintenance: false,
    unsubscribe: null as any
  }),
  methods: {
    iniPneu(pneu: any) {
      this.maintenance = false
      const sm_repartitionRoue: string = pneu.lesEmplacement
      if (sm_repartitionRoue != "" && sm_repartitionRoue !== undefined) {
        const emplacementPneu = sm_repartitionRoue.split(" ")
        for (const uneLigne of this.schemasPneu) {
          if (uneLigne.ligne != 2) {
            for (const uneColonne of uneLigne.colonnes) {
              uneColonne.color = "#ECEFF1"
              let trouve = false
              let i = 0
              while (trouve == false && i < emplacementPneu.length && uneLigne.ligne != 2) {
                if (emplacementPneu[i] == uneColonne.emplacement) {
                  trouve = true
                  uneColonne.color = "black"
                }
                i++
              }
            }
          }
        }
      } else {
        for (const uneLigne of this.schemasPneu) {
          if (uneLigne.ligne != 2) {
            for (const uneColonne of uneLigne.colonnes) {
              uneColonne.color = "#ECEFF1"
            }
          }
        }
      }
    },
    iniPneuMaintenance(pneu: any) {
      this.maintenance = true
      const sm_repartitionRoue: string = pneu.lesEmplacement
      if (sm_repartitionRoue != "" && sm_repartitionRoue !== undefined) {
        const emplacementPneu = sm_repartitionRoue.split(" ")
        for (const uneLigne of this.schemasPneu) {
          if (uneLigne.ligne != 2) {
            for (const uneColonne of uneLigne.colonnes) {
              uneColonne.color = pneu.couleurFond
              uneColonne.afficherEmplacement = false
              uneColonne.style = ""
              let trouve = false
              let i = 0
              while (trouve == false && i < emplacementPneu.length && uneLigne.ligne != 2) {
                if (emplacementPneu[i] == uneColonne.emplacement) {
                  trouve = true
                  uneColonne.color = "black"
                  uneColonne.afficherEmplacement = true
                  uneColonne.style = "box-shadow: 1px 1px 3px gray; border-radius: 10% / 50%;"
                }
                i++
              }
            }
          }
        }
      } else {
        for (const uneLigne of this.schemasPneu) {
          if (uneLigne.ligne != 2) {
            for (const uneColonne of uneLigne.colonnes) {
              uneColonne.color = "white"
            }
          }
        }
      }
    },
    sansPneu(pneu: any) {
      for (const uneLigne of this.schemasPneu) {
        for (const uneColonne of uneLigne.colonnes) {
          if (uneLigne.ligne != 2) {
            uneColonne.color = pneu.couleurFond
            uneColonne.afficherEmplacement = false
            uneColonne.style = ""
          }
        }
      }
    },
    modifierEmplacementPneu(emplacement: string) {
      if (this.maintenance == true) {
        this.selectionnerEmplacement(emplacement)
      } else {
        let lesEmplacement = this.$store.state.pneu.lesEmplacement
        if (lesEmplacement == "") {
          lesEmplacement = emplacement
        } else {
          const splitEmplacement = lesEmplacement.split(" ")
          const existe = splitEmplacement.findIndex((e: string) => e == emplacement)
          if (existe == -1) {
            lesEmplacement += " " + emplacement
          } else {
            splitEmplacement.splice(existe, 1)
            if (splitEmplacement.length >= 1) {
              lesEmplacement = splitEmplacement[0]
              for (let i = 1; i < splitEmplacement.length; i++) {
                lesEmplacement += " " + splitEmplacement[i]
              }
            } else {
              lesEmplacement = ""
            }
          }
        }
        lesEmplacement = this.triePneu(lesEmplacement)
        this.$store.commit("majPneu", { lesEmplacement: lesEmplacement, etat: "ModifierEmplacement" })
      }
    },
    selectionnerEmplacement(emplacement: string) {
      this.maintenance = true
      let lesColonnes: any[] = []
      for (const uneLigne of this.schemasPneu) {
        for (const uneColonne of uneLigne.colonnes) {
          if (uneLigne.ligne != 2 && uneColonne.afficherEmplacement == true) {
            lesColonnes.push(uneColonne)
          }
        }
      }

      for (const uneColonne of lesColonnes) {
        if (uneColonne.emplacement == emplacement) {
          uneColonne.color = "#ECEFF1"
          uneColonne.style = "box-shadow: 2px 2px 4px black; border-radius: 10% / 50%;"
        } else {
          uneColonne.color = "black"
          uneColonne.style = "box-shadow: 1px 1px 3px gray; border-radius: 10% / 50%;"
        }
      }

      const existe = lesColonnes.find((c: any) => c.emplacement == emplacement)
      if (existe !== undefined) {
        this.$store.commit("majPneu", { lesEmplacement: this.$store.state.pneu.lesEmplacement, etat: "SELECTIONNER_PNEU", emplacmenentSelectionner: emplacement })
      } else {
        this.$store.commit("majPneu", { lesEmplacement: this.$store.state.pneu.lesEmplacement, etat: "SELECTIONNER_PNEU", emplacmenentSelectionner: "" })
      }
    },
    triePneu(lesEmplacement: any) {
      if (lesEmplacement != "") {
        const unAutreSplit = lesEmplacement.split(" ")
        unAutreSplit.sort((a: string, b: string) => {
          if (a.includes("1") && !b.includes("1")) return -1
          if (!a.includes("1") && b.includes("1")) return 1
          if (a.includes("2") && !b.includes("2")) return -1
          if (!a.includes("2") && b.includes("2")) return 1
          if (a.includes("3") && !b.includes("3")) return -1
          if (!a.includes("3") && b.includes("3")) return 1
          if (a.includes("4") && !b.includes("4")) return -1
          if (!a.includes("4") && b.includes("4")) return 1
          if (a.includes("5") && !b.includes("5")) return -1
          if (!a.includes("5") && b.includes("5")) return 1
        })
        unAutreSplit.sort((a: string, b: string) => {
          if (a.includes("DE") && !b.includes("DE")) return -1
          if (!a.includes("DE") && b.includes("DE")) return 1
          if (a.includes("DI") && !b.includes("DI")) return -1
          if (!a.includes("DI") && b.includes("DI")) return 1
          if (a.includes("GI") && !b.includes("GI")) return -1
          if (!a.includes("GI") && b.includes("GI")) return 1
          if (a.includes("GE") && !b.includes("GE")) return -1
          if (!a.includes("GE") && b.includes("GE")) return 1
        })

        lesEmplacement = unAutreSplit[0]
        for (let i = 1; i < unAutreSplit.length; i++) {
          lesEmplacement += " " + unAutreSplit[i]
        }
      }
      return lesEmplacement
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majPneu":
          if (this.$store.state.pneu.etat == "AFFICHER_PNEU") {
            this.iniPneuMaintenance(this.$store.state.pneu)
          } else if (this.$store.state.pneu.etat == "SELECTIONNER_PNEU") {
            break
          } else if (this.$store.state.pneu.etat == "SANS_PNEU") {
            this.sansPneu(this.$store.state.pneu)
            break
          } else {
            this.iniPneu(this.$store.state.pneu)
          }
          break
      }
    })
  },
  mounted() {
    this.$store.commit("majPneu", { lesEmplacement: "", etat: "mounted" })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
